.drawer-container > .MuiDrawer-paper {
  background-color: #e6f2fb !important;
}

.drawer-container {
  padding: 20px;
}

.drawer-logo {
  width: 100%;
}

.drawer-main-children {
  background-color: #fcfcfd;
  min-height: 100vh;
}

.container-img {
  padding: 10px 0px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.container-chevron {
  border-radius: 6px;
  padding: 8px;
  background: #ebf6ee;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.lg-avatar {
  width: 48px !important;
  height: 48px !important;
  border-radius: 6px !important;
}

.mini-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px !important;
}

.list-menu-container {
  padding: 10px 10px !important;
  margin: 14px 0px !important;
  height: inherit;
}
.list-menu-container-footer {
  padding: 10px !important;
}

.drawer-header-container {
  height: auto !important;
  min-height: auto !important;
}

.footer-logout-container {
  border-radius: 4px;
  border: 1px solid #f7b4ae;
  padding: 0 !important;
}

.footer-logout-button {
  justify-content: center !important;
}

.logout-text {
  color: #ea4335;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.footer-logout-container:hover {
  background-color: #ea4335;
}

.footer-logout-container:hover .logout-text,
.footer-logout-container:hover .logout-button {
  color: #ffffff !important;
}

.dialog-delete-header {
  padding: 0 !important;
  margin-top: 10px !important;
}

.dialog-delete-actions {
  margin-bottom: 10px !important;
}

.MuiListItemButton-root.Mui-selected {
  border-radius: 4px !important;
  background: #0078d7 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.MuiListItemButton-root.Mui-selected > .MuiListItemIcon-root {
  color: white !important;
}

.logout-button {
  color: #ea4335 !important;
}

.MuiListItemButton-root {
  color: #0078d7 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.button-list-icon {
  color: #0078d7 !important;
}

.list-menu-container {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #bdbdbd transparent;
}
